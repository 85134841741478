import React, { useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetState } from "../../features/global/globalSlice";
import { RootState } from "../../store";
import { resetForm } from "../../features/form/formSlice";
interface IHeader {
  name?: string;
}

const Header: React.FC<IHeader> = ({ name }) => {
  const dispatch = useDispatch();

  const playerInUse = useSelector(
    (state: RootState) => state.global.playerInUse
  );
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleLogoutClick = () => {
    setOpen(true); // Open the modal when logout is clicked
  };

  const handleClose = () => {
    setOpen(false); // Close the modal without logging out
  };

  const handleConfirmLogout = () => {
    setOpen(false);
    localStorage.clear();
    dispatch(resetState());
    dispatch(resetForm());
    
    // Optional: Add a short delay to allow state updates to complete
    setTimeout(() => {
      window.location.reload();
    }, 100); // 100 ms delay
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <IconButton
            disabled={playerInUse}
            onClickCapture={() => navigate(-1)}
            sx={{ ml: 0.5 }}
            edge="start"
            aria-label="back"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" ml={2}>
            {name}
          </Typography>
        </Box>
        <IconButton
          disabled={playerInUse}
          sx={{ mr: 0.5 }}
          aria-label="logout"
          onClick={handleLogoutClick}
        >
          <LogoutIcon />
        </IconButton>
      </Box>
      <hr
        style={{
          border: "none",
          height: "1px",
          backgroundColor: "#ccc",
          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
          margin: "8px 0",
        }}
      />

      {/* Logout Confirmation Modal */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Logout</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmLogout} color="primary" autoFocus>
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Header;
