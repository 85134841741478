import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
} from "@mui/material";

type QuestionComponentProps = {
  questionNumber: number;
  question: string;
  description?: string;
  gifUrl: string;
  followUp?: {
    prompt: string;
    placeholder: string;
  };
  existingResponse?: { answer: boolean; duration?: string }; // Add optional existing response
  onResponse: (response: { answer: boolean; duration?: string }) => void;
};

const Question: React.FC<QuestionComponentProps> = ({
  questionNumber,
  question,
  description,
  gifUrl,
  followUp,
  existingResponse,
  onResponse,
}) => {
  // Initialize state with existingResponse or default values
  const [answer, setAnswer] = useState<"yes" | "no" | null>(
    existingResponse?.answer ? "yes" : existingResponse?.answer === false ? "no" : null
  );
  const [duration, setDuration] = useState<string>(existingResponse?.duration || "");
  const handleAnswerChange = (selectedAnswer: "yes" | "no") => {
    setAnswer(selectedAnswer);
    if (selectedAnswer === "no") {
      setDuration(""); // Reset duration if "No" is selected
    }
    onResponse({
      answer: selectedAnswer === "yes",
      duration: selectedAnswer === "yes" ? duration : undefined,
    });
  };

  const handleDurationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDuration = e.target.value;
    setDuration(newDuration);
    onResponse({ answer: true, duration: newDuration });
  };

  useEffect(() => {
    if (answer === "no") {
      setDuration(""); // Clear duration if the answer is "No"
    }
  }, [answer]);

  return (
    <Box>
      <Typography variant="h6">{`${questionNumber}. ${question}`}</Typography>
      {description && (
        <Typography variant="body2" color="textSecondary">
          {description}
        </Typography>
      )}
      <Box my={2}>
        <img src={gifUrl} alt="Related GIF" style={{ width: "100%", maxWidth: "300px" }} />
      </Box>
      <RadioGroup
        row
        value={answer}
        onChange={(e) => handleAnswerChange(e.target.value as "yes" | "no")}
      >
        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
        <FormControlLabel value="no" control={<Radio />} label="No" />
      </RadioGroup>
      {answer === "yes" && followUp && (
        <Box mt={2}>
          <TextField
            label={followUp.prompt}
            value={duration}
            onChange={handleDurationChange}
            placeholder={followUp.placeholder}
            fullWidth
            required
          />
        </Box>
      )}
    </Box>
  );
};

export default Question;
