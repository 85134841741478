import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useReactMediaRecorder } from "react-media-recorder";
import {
  IconButton,
  Typography,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import MicIcon from "@mui/icons-material/Mic";
import CloseIcon from "@mui/icons-material/Close";
import { RootState } from "../../store";
import { setPlayerInUse } from "../../features/global/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { detectCough } from "../../services/ml";
import { COUGH_DETECTOR_THRESHOLD } from "../../constants/values";

interface IAudioRecorder {
  pk: number;
  id: string;
  title: string;
  subtitle: string;
  minDuration: number;
  onSave: (data: { [key: string]: File | number }) => void;
}

const AudioRecorder: React.FC<IAudioRecorder> = ({
  pk,
  id,
  title,
  subtitle,
  minDuration,
  onSave,
}) => {
  const [recordingDuration, setRecordingDuration] = useState(0);
  const [isRecording, setIsRecording] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [audioPlayer, setAudioPlayer] = useState<HTMLAudioElement | null>(null);
  const [coughNotDetected, setCoughNotDetected] = useState(false);
  const [loading, setLoading] = useState(false); // State for loading modal
  const dispatch = useDispatch();
  const playerInUse = useSelector(
    (state: RootState) => state.global.playerInUse
  );

  const { startRecording, stopRecording } = useReactMediaRecorder({
    async onStop(blobUrl, blob) {
      setAudioUrl(blobUrl);
      const audio = new Audio(blobUrl);
      audio.onended = () => setIsPlaying(false);
      setAudioPlayer(audio);

      const audioFile = new File([blob], `${id}_audio_${uuidv4()}.wav`, {
        type: "audio/wav",
      });
      let data = {};
      // pk 4 corresponds to background sound, we don't need to run cough detector for background sound
      if (pk !== 4) {
        setLoading(true); // Show loading modal when API is called
        const response = await detectCough(audioFile);
        setLoading(false); // Hide loading modal when API response is received

        if (response.ok) {
          const content = await response.json();
          const { score } = content;
          const scoreString = `cough_detector_score${pk}`;
          if (score < COUGH_DETECTOR_THRESHOLD) {
            setCoughNotDetected(true);
            setAudioUrl(null); // Discard the recorded audio
            setAudioPlayer(null);
            data = {
              [id]: null,
              [scoreString]: null,
            };
          } else {
            data = {
              [id]: audioFile,
              [scoreString]: score,
            };
          }
        }
      } else {
        data = {
          [id]: audioFile,
        };
      }
      dispatch(setPlayerInUse(false)); // Reset playerInUse after stopping
      onSave(data);
    },
    askPermissionOnMount: true,
    mediaRecorderOptions: {
      mimeType: "audio/wav",
      audioBitsPerSecond: 32000,
    },
  });
  useEffect(() => {
    if (audioPlayer) {
      audioPlayer.onended = () => {
        setIsPlaying(false);
        dispatch(setPlayerInUse(false)); // Reset global state when playback ends
      };
    }
    return () => {
      if (audioPlayer) {
        audioPlayer.onended = null; // Clean up event listener
      }
    };
  }, [audioPlayer, dispatch]);
  

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isRecording) {
      interval = setInterval(() => {
        setRecordingDuration((prev) => prev + 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isRecording]);

  const handleStartRecording = () => {
    setIsRecording(true);
    setRecordingDuration(0);
    setAudioUrl(null);
    dispatch(setPlayerInUse(true)); // Set playerInUse to true when recording starts
    startRecording();
  };

  const handleStopRecording = () => {
    setIsRecording(false);
    stopRecording();
  };

  const handlePlayAudio = () => {
    if (audioPlayer) {
      setIsPlaying(true);
      dispatch(setPlayerInUse(true)); // Set playerInUse to true when playing starts
      audioPlayer.play();
    }
  };

  const handleStopAudio = () => {
    if (audioPlayer) {
      audioPlayer.pause();
      audioPlayer.currentTime = 0;
      setIsPlaying(false);
      dispatch(setPlayerInUse(false)); // Set playerInUse to false when playback stops
    }
  };

  const handleReRecord = () => {
    setAudioUrl(null);
    setAudioPlayer(null);
    handleStartRecording();
  };

  return (
    <Box
      key={id}
      sx={{ padding: 2, border: "1px solid #ccc", borderRadius: 2, margin: 2 }}
    >
      <Typography variant="h6">{title}</Typography>
      <Typography variant="subtitle1">{subtitle}</Typography>
      <Typography variant="body2">
        Recording Duration: {recordingDuration}s
      </Typography>
      <Box display="flex" justifyContent="center">
        {!isRecording && !audioUrl && (
          <Box
            sx={{
              borderRadius: "50%",
              backgroundColor: "#f0f0f0",
              padding: 2,
              boxShadow: 3,
            }}
          >
            <IconButton
              onClick={handleStartRecording}
              color="primary"
              disabled={playerInUse}
            >
              <MicIcon fontSize="large" />
            </IconButton>
          </Box>
        )}

        {isRecording && (
          <Box
            sx={{
              borderRadius: "50%",
              backgroundColor: "#f0f0f0",
              padding: 2,
              boxShadow: 3,
            }}
          >
            <IconButton
              onClick={handleStopRecording}
              color="secondary"
              disabled={recordingDuration < minDuration}
            >
              <StopIcon fontSize="large" />
            </IconButton>
          </Box>
        )}

        {!isRecording && audioUrl && (
          <>
            {!isPlaying && (
              <Box
                sx={{
                  borderRadius: "50%",
                  backgroundColor: "#f0f0f0",
                  padding: 2,
                  boxShadow: 3,
                }}
              >
                <IconButton
                  onClick={handlePlayAudio}
                  color="primary"
                  disabled={playerInUse}
                >
                  <PlayArrowIcon fontSize="large" />
                </IconButton>
              </Box>
            )}
            {isPlaying && (
              <Box
                sx={{
                  borderRadius: "50%",
                  backgroundColor: "#f0f0f0",
                  padding: 2,
                  boxShadow: 3,
                }}
              >
                <IconButton onClick={handleStopAudio} color="secondary">
                  <StopIcon fontSize="large" />
                </IconButton>
              </Box>
            )}
          </>
        )}
      </Box>
      {!isRecording && audioUrl && (
        <Box display="flex" justifyContent="center">
          <Button onClick={handleReRecord} color="error" disabled={playerInUse}>
            Record again
          </Button>
        </Box>
      )}

      {/* Modal for Loading */}
      <Dialog
        open={loading}
        onClose={() => setLoading(false)}
        aria-labelledby="loading-dialog-title"
      >
        <DialogTitle id="loading-dialog-title">Please Wait</DialogTitle>
        <DialogContent>
          <DialogContentText>
            We are processing your recording. Please wait...
          </DialogContentText>
          <CircularProgress sx={{ display: "block", margin: "auto", mt: 2 }} />
        </DialogContent>
      </Dialog>

      {/* Modal for Cough Not Detected */}
      <Dialog
        open={coughNotDetected}
        onClose={() => setCoughNotDetected(false)} // Just close the dialog without starting recording
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Cough Not Detected
          <IconButton
            aria-label="close"
            onClick={() => setCoughNotDetected(false)} // Just close the dialog
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            No cough was detected in your recording. Please try again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCoughNotDetected(false); // Close the dialog
              handleReRecord(); // Start a new recording
            }}
            color="primary"
          >
            Retake
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AudioRecorder;
