import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";
import { useDispatch } from "react-redux";
import { setAuthenticated } from "./features/global/globalSlice";
const App: React.FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const { token } = JSON.parse(localStorage.getItem("user-details") || "{}");
    if (token) {
      dispatch(setAuthenticated(true));
    }
  }, [dispatch]);
  return (
    <Router>
      <AppRoutes />
    </Router>
  );
};

export default App;
