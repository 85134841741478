import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IndividualDetails {
  nikshayId: string;
  name: string;
  age: string;
  gender: string;
  phone_number: string;
}

interface DeviceDetails {
  userAgent: string | null;
}

interface LocationDetails {
  latitude: number | null;
  longitude: number | null;
  accuracy: number | null;
  altitude?: number | null;
  altitudeAccuracy?: number | null;
}

interface ExtendedIndividualDetails extends IndividualDetails {
  deviceDetails: DeviceDetails;
  locationDetails: LocationDetails | null;
}

interface Response {
  key: string;
  answer: boolean;
  duration?: string;
}

interface FormState {
  individualDetails: ExtendedIndividualDetails;
  responses: Response[];
}

const initialState: FormState = {
  individualDetails: {
    nikshayId: "",
    name: "",
    age: "",
    gender: "",
    phone_number: "",
    deviceDetails: {
      userAgent: "",
    },
    locationDetails: null,
  },
  responses: [],
};

const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    setIndividualDetails(state, action: PayloadAction<IndividualDetails>) {
      state.individualDetails = {
        ...state.individualDetails,
        ...action.payload,
      };
    },
    setDeviceDetails(state, action: PayloadAction<DeviceDetails>) {
      state.individualDetails.deviceDetails = action.payload;
    },
    setLocationDetails(state, action: PayloadAction<LocationDetails>) {
      state.individualDetails.locationDetails = action.payload;
    },
    setResponses(state, action: PayloadAction<Response[]>) {
      state.responses = action.payload;
    },
    resetForm(state) {
      state.individualDetails = {
        nikshayId: "",
        name: "",
        age: "",
        gender: "",
        phone_number: "",
        deviceDetails: {
          userAgent: "",
        },
        locationDetails: null,
      };
      state.responses = [];
    },
  },
});

export const {
  setIndividualDetails,
  setDeviceDetails,
  setLocationDetails,
  setResponses,
  resetForm,
} = formSlice.actions;
export default formSlice.reducer;
