export const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  export const getAuthToken = async () => {
    const { token } = JSON.parse(
      localStorage.getItem("user-details") || "{}"
    );
    return token;
  }