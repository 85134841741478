import { BASE_URL } from "../constants/api";
import { headers } from ".";

const ENDPOINTS = {
  GET_STATES: "states/",
  GET_STATE_BY_ID: "states/", // Append state ID dynamically
};

// General method for fetching data (GET request)
const locationService = async (endpointPath: string) => {
  const rawResponse = await fetch(`${BASE_URL}${endpointPath}`, {
    method: "GET",
    headers,
  });
  return rawResponse.json(); // Assuming we want to return JSON data directly
};

// Fetch list of all states
export const getStates = async () => locationService(ENDPOINTS.GET_STATES);

// Fetch a specific state by ID
export const getStateById = async (stateId: number) =>
  locationService(`${ENDPOINTS.GET_STATE_BY_ID}${stateId}/`);
