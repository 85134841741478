import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../pages/Login";
import IndividualDetails from "../pages/IndividualDetails";
import UserDetails from "../pages/UserDetails";
import Symptoms from "../pages/Symptoms";
import Recording from "../pages/Recording";
import AuthenticatedRoute from "../components/AuthenticatedRoute";
import UnauthenticatedRoute from "../components/UnauthenticatedRoute";

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<AuthenticatedRoute children={<IndividualDetails />} />}
      />

      <Route
        path="/login"
        element={<UnauthenticatedRoute children={<Login />} />}
      />
      <Route
        path="/user-details"
        element={<UnauthenticatedRoute children={<UserDetails />} />}
      />
      <Route
        path="/patient-details"
        element={<AuthenticatedRoute children={<IndividualDetails />} />}
      />
      <Route
        path="/symptoms"
        element={<AuthenticatedRoute children={<Symptoms />} />}
      />
      <Route
        path="/recordings"
        element={<AuthenticatedRoute children={<Recording />} />}
      />
    </Routes>
  );
};

export default AppRoutes;
