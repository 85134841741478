// src/store/globalSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface GlobalState {
  isAuthenticated: boolean;
  playerInUse: boolean;
}

const initialState: GlobalState = {
  isAuthenticated: Boolean(localStorage.getItem("user-details")),
  playerInUse: false,
};

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    setPlayerInUse: (state, action: PayloadAction<boolean>) => {
      state.playerInUse = action.payload;
    },
    resetState: () => initialState,
  },
});

export const { setAuthenticated, setPlayerInUse, resetState } =
  globalSlice.actions;
export default globalSlice.reducer;
