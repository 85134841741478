import { getAuthToken } from ".";
import { BASE_URL } from "../constants/api";

const ENDPOINTS = {
  DATA_COLLECTION: "data/recordings/create/",
};

const headers = (token: string) => ({
  Authorization: `Token ${token}`,
});

// General method for sending a POST request with FormData
const apiService = async (
  endpointPath: string,
  method: string,
  data: FormData
) => {
  const token = await getAuthToken();
  const response = await fetch(`${BASE_URL}${endpointPath}`, {
    method,
    headers: token ? headers(token) : {},
    body: data,
  });

  return response;
};

// Data collection function that sends FormData to the server
export const sendDataCollection = async (formData: FormData) =>
  apiService(ENDPOINTS.DATA_COLLECTION, "POST", formData);
