import { getAuthToken } from ".";
import { BASE_URL } from "../constants/api";
const headers = (token: string) => ({
  Authorization: `Token ${token}`,
});
const ENDPOINTS = {
  COUGH_DETECTOR: "cough-detector/", // Endpoint for cough detection
};

// General method for making POST requests
const postService = async (endpointPath: string, formData: FormData) => {
  const token = await getAuthToken();
  const rawResponse = await fetch(`${BASE_URL}${endpointPath}`, {
    headers: token ? headers(token) : {},
    method: "POST",
    body: formData,
  });

  return rawResponse;
};

// Detect cough from an uploaded audio file
export const detectCough = async (file: File) => {
  // Create a FormData object to send the file
  const formData = new FormData();
  formData.append("audio_file", file);

  return postService(ENDPOINTS.COUGH_DETECTOR, formData);
};
