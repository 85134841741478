import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom"; // Or 'next/router' if using Next.js
import { RootState } from "../../store";

interface UnauthenticatedRouteProps {
  children: React.ReactNode;
}

const UnauthenticatedRoute: React.FC<UnauthenticatedRouteProps> = ({
  children,
}) => {
  const isAuthenticated = useSelector(
    (state: RootState) => state.global.isAuthenticated
  );
  // Redirect to dashboard or home if authenticated
  if (isAuthenticated) {
    return <Navigate to="/patient-details" />;
  }

  return <>{children}</>; // Render login or signup content if not authenticated
};

export default UnauthenticatedRoute;
