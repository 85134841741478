import React, { useEffect } from "react";
import MultiQuestionnaire from "../../components/MultiQuestionnaire";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { setResponses } from "../../features/form/formSlice";

type Response = {
  key: string;
  answer: boolean;
  duration?: string;
};
const Symptoms = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { nikshayId } = useSelector(
    (state: RootState) => state.form.individualDetails
  );
  const responses = useSelector((state: RootState) => state.form.responses);
  useEffect(() => {
    // Check if nikshayId is missing
    if (!nikshayId) {
      navigate("/"); // Redirect if nikshayId is not present
    }
  }, [nikshayId, navigate]);

  const handleSubmit = (newResponses: Response[]) => {
    dispatch(setResponses(newResponses)); // Save responses to Redux
    navigate("/recordings"); // Navigate to next screen
  };

  return (
    <>
      <Header name={"Symptoms"} />
      <MultiQuestionnaire
        onSubmit={handleSubmit}
        existingResponses={responses}
      />
    </>
  );
};

export default Symptoms;
