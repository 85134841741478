import { v4 as uuidv4 } from "uuid";
import { COUGH_DETECTOR_THRESHOLD } from "../constants/values";

interface IndividualDetails {
  nikshayId: string;
  name: string;
  age: string;
  gender: string;
  phone_number: string;
}

interface DeviceDetails {
  userAgent: string | null;
}

interface LocationDetails {
  latitude: number | null;
  longitude: number | null;
  accuracy: number | null;
  altitude?: number | null;
  altitudeAccuracy?: number | null;
}

interface ExtendedIndividualDetails extends IndividualDetails {
  deviceDetails: DeviceDetails;
  locationDetails: LocationDetails | null;
}
type Response = {
  key: string;
  answer: boolean;
  duration?: string;
};

type NikshayData = {
  individualDetails: ExtendedIndividualDetails;
  responses: Response[];
  recordings: {
    cough_recording_1?: File;
    cough_recording_2?: File;
    cough_recording_3?: File;
    background_sound?: File;
    cough_detector_score1?: number;
    cough_detector_score2?: number;
    cough_detector_score3?: number;
  };
};

export function parseData(data: NikshayData): FormData {
  const formData = new FormData();

  // Append nikshay_id to FormData
  const uuid = uuidv4();
  formData.append("nikshay_id", data.individualDetails.nikshayId);
  formData.append("phone_number", data.individualDetails.phone_number);

  formData.append("name", data.individualDetails.name);
  formData.append("age", data.individualDetails.age);
  formData.append("gender", data.individualDetails.gender);

  const latitude = data.individualDetails.locationDetails?.latitude;
  const longitude = data.individualDetails.locationDetails?.longitude;
  const altitude = data.individualDetails.locationDetails?.altitude;
  const altitudeAccuracy = data.individualDetails.locationDetails?.altitudeAccuracy;

  formData.append("latitude", JSON.stringify(latitude || 0.0));
  formData.append("longitude", JSON.stringify(longitude || 0.0));
  formData.append("altitude", JSON.stringify(altitude || 0.0) );
  formData.append("location_accuracy", JSON.stringify(altitudeAccuracy || 0.0));
  formData.append(
    "device_details",
    JSON.stringify(data.individualDetails.deviceDetails)
  );
  formData.append("uuid", uuid);

  // Process each response in the responses array
  data.responses.forEach((response) => {
    const key = response.key;
    const answer = response.answer ? "yes" : "no";

    // Append answer with "yes" or "no"
    formData.append(key, answer);

    // Append duration (or 0 if undefined) with key_duration suffix
    const duration = response.duration ? parseFloat(response.duration) : 0;
    formData.append(`${key}_duration`, duration.toString());
  });

  // Add each file to FormData
  formData.append(
    "cough_detector_threshold",
    COUGH_DETECTOR_THRESHOLD.toString()
  );
  const files: Record<string, File | undefined> = {
    cough_recording_1: data.recordings.cough_recording_1,
    cough_recording_2: data.recordings.cough_recording_2,
    cough_recording_3: data.recordings.cough_recording_3,
    background_sound: data.recordings.background_sound,
  };

  const scores = {
    cough_detector_score1: data.recordings.cough_detector_score1,
    cough_detector_score2: data.recordings.cough_detector_score2,
    cough_detector_score3: data.recordings.cough_detector_score3,
  };
  Object.entries(scores).forEach(([key, score]) => {
    if (score) {
      formData.append(key, JSON.stringify(score));
    }
  });
  Object.entries(files).forEach(([key, file]) => {
    if (file) {
      formData.append(key, file);
    }
  });

  return formData;
}

export function getDeviceDetails() {
  const userAgent = navigator.userAgent; // Browser and OS info
  return {
    userAgent,
  };
}

export function getUserLocation() {
  return new Promise((resolve) => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            accuracy: position.coords.accuracy,
            altitude: position.coords.altitude || null, // Return null if not available
            altitudeAccuracy: position.coords.altitudeAccuracy || null, // Return null if not available
          });
        },
        (error) => {
          console.warn("Geolocation error:", error.message);
          resolve({
            latitude: null,
            longitude: null,
            accuracy: null,
            altitude: null,
            altitudeAccuracy: null,
          }); // Return null values for all fields on failure
        }
      );
    } else {
      console.warn("Geolocation is not supported by this browser.");
      resolve({
        latitude: null,
        longitude: null,
        accuracy: null,
        altitude: null,
        altitudeAccuracy: null,
      }); // Return null values for unsupported browsers
    }
  });
}
