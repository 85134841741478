import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';  // Or 'next/router' if using Next.js
import { RootState } from '../../store';

interface AuthenticatedRouteProps {
  children: React.ReactNode;
}

const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = ({ children }) => {
  const isAuthenticated = useSelector((state: RootState) => state.global.isAuthenticated);

  // Redirect to login page if not authenticated
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return <>{children}</>;  // Render the protected content if authenticated
};

export default AuthenticatedRoute;
