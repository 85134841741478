import { getAuthToken } from ".";
import { BASE_URL } from "../constants/api";

const headers = (token: string) => ({
  Authorization: `Token ${token}`,
});

const ENDPOINTS = {
  VALIDATE_FETCH: (id: string) => `validate-and-fetch/${id}/`,
};

// General method for making GET requests
const getService = async (endpointPath: string) => {
  const token = await getAuthToken();
  const rawResponse = await fetch(`${BASE_URL}${endpointPath}`, {
    headers: token ? headers(token) : {},
    method: "GET",
  });

  return rawResponse;
};

// Validate and fetch data for a specific ID
export const validateAndFetch = async (id: string) => {
  return getService(ENDPOINTS.VALIDATE_FETCH(id));
};
