import { BASE_URL } from "../constants/api";
import { headers } from ".";

const ENDPOINTS = {
  REQUEST_OTP: "auth/otp/request/",
  VALIDATE_OTP: "auth/otp/validate/",
  VALIDATE_PASSWORD: "auth/password/validate/",
  CREATE_USER: "auth/user/create/",
  GET_USER_PROFILE: "auth/profile/",
};

const method = "post";

const authService = async (endpointPath: string, bodyData: any) => {
  const rawResponse = await fetch(`${BASE_URL}${endpointPath}`, {
    method,
    headers,
    body: JSON.stringify(bodyData),
  });
  return rawResponse;
};

// Service functions for each endpoint
export const requestOtp = async (phoneNumber: string) =>
  authService(ENDPOINTS.REQUEST_OTP, { phone_number: phoneNumber });

export const validateOtp = async (phoneNumber: string, otp: string) =>
  authService(ENDPOINTS.VALIDATE_OTP, { phone_number: phoneNumber, otp });

export const validatePassword = async (password: string) =>
  authService(ENDPOINTS.VALIDATE_PASSWORD, { password });

export const createUser = async (data: any) =>
  authService(ENDPOINTS.CREATE_USER, data);

export const getUserProfile = async () =>
  authService(ENDPOINTS.GET_USER_PROFILE, {});
