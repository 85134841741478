// src/store/index.ts
import { configureStore } from "@reduxjs/toolkit";
import globalReducer from "../features/global/globalSlice";
import formReducer from "../features/form/formSlice";
const store = configureStore({
  reducer: {
    global: globalReducer,
    form: formReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>; // To get the RootState type
export type AppDispatch = typeof store.dispatch; // To get dispatch type

export default store;
