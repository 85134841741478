import React, { useState } from "react";
import { Container, Box, Button, Typography, Alert } from "@mui/material";
import Question from "../Question";
import COUGH_GIF from "../../assets/images/cough.gif";
import FEVER_GIF from "../../assets/images/fever.gif";
import LOA_GIF from "../../assets/images/loss_of_appetite.gif";
import WL_GIF from "../../assets/images/loss_of_weight.gif";
import SOB_GIF from "../../assets/images/shortness_of_breath.gif";
import CP_GIF from "../../assets/images/chest_pain.gif";
import NS_GIF from "../../assets/images/night_sweats.gif";
import H_GIF from "../../assets/images/hemoptysis.gif";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

type QuestionType = {
  key: string;
  questionNumber: number;
  question: string;
  description?: string;
  gifUrl: string;
  followUp?: {
    prompt: string;
    placeholder: string;
  };
};

type Response = {
  key: string;
  answer: boolean;
  duration?: string;
};

const questions: QuestionType[] = [
  {
    key: "fever",
    questionNumber: 1,
    question: "Do you have fever?",
    description: "Raised body temperature (more than 98° F or 37° C)",
    gifUrl: FEVER_GIF,
    followUp: {
      prompt: "If Yes Since how many days do you have fever?",
      placeholder: "e.g., 3",
    },
  },
  {
    key: "loss_of_appetite",
    questionNumber: 2,
    question: "Do you have loss of appetite (hunger)?",
    description:
      "Has your food consumption reduced, compared to what you ate earlier?",
    gifUrl: LOA_GIF,
    followUp: {
      prompt: "If Yes Since how many days do you have a loss of appetite?",
      placeholder: "e.g., 3",
    },
  },
  {
    key: "weight_loss",
    questionNumber: 3,
    question: "Have you lost weight without trying in the last 6 months ?",
    description:
      "loss of 4-5% (or more) of the usual body weight over a period of 6-12 months",
    gifUrl: WL_GIF,
    followUp: {
      prompt: "If Yes Since how many days are you having weight loss?",
      placeholder: "e.g., 3",
    },
  },
  {
    key: "shortness_of_breath",
    questionNumber: 4,
    question: "Do you experience shortness of breath frequently?",
    description: "Do you experience difficulty in breathing?",
    gifUrl: SOB_GIF,
    followUp: {
      prompt: "If Yes Since how many days are you having shortness of breath?",
      placeholder: "e.g., 3",
    },
  },
  {
    key: "chest_pain",
    questionNumber: 5,
    question: "Have you experienced chest pain in the past 6 months?",
    gifUrl: CP_GIF,
    followUp: {
      prompt: "If Yes Since how many days are you having chest pain?",
      placeholder: "e.g., 3",
    },
  },
  {
    key: "night_sweat",
    questionNumber: 6,
    question: "Have you experienced night sweats in the last month?",
    gifUrl: NS_GIF,
    followUp: {
      prompt: "If Yes Since how many days are you having night sweats?",
      placeholder: "e.g., 3",
    },
  },
  {
    key: "cough",
    questionNumber: 7,
    question: "Do you have cough?",
    gifUrl: COUGH_GIF,
    followUp: {
      prompt: "If Yes Since how many days are you having cough?",
      placeholder: "e.g., 3",
    },
  },
  {
    key: "hemoptysis",
    questionNumber: 8,
    question:
      "Have you coughed up sputum with blood anytime in the past 6 months?",
    gifUrl: H_GIF,
    followUp: {
      prompt: "If Yes Since how many days are you having this issue?",
      placeholder: "e.g., 3",
    },
  },
];
// Define the props for the MultiQuestionnaire component
interface MultiQuestionnaireProps {
  onSubmit: (responses: Response[]) => void;
  existingResponses?: (Response | null)[]; // Optional, as it may not be passed initially
}

const MultiQuestionnaire: React.FC<MultiQuestionnaireProps> = ({
  onSubmit,
  existingResponses,
}) => {
  const [responses, setResponses] = useState(
    existingResponses || Array(questions.length).fill(null)
  );

  const [error, setError] = useState<string | null>(null);

  const handleResponse = (index: number, response: Omit<Response, "key">) => {
    const newResponses = [...responses];
    newResponses[index] = { key: questions[index].key, ...response };
    setResponses(newResponses);
  };

  const handleSubmit = () => {
    // Validation: Check all questions are answered, and duration is filled if answer is "Yes"
    for (let i = 0; i < questions.length; i++) {
      const response = responses[i];
      if (!response) {
        setError("Please answer all questions.");
        return;
      }
      if (response.answer && !response.duration) {
        setError(
          "Please specify duration for question ."
        );
        return;
      }
    }

    setError(null);
    console.log("All Responses:", responses);
    onSubmit(responses as Response[]);
    // Optionally send responses to a server or process them as needed
  };

  return (
    <Container>
      {questions.map((question, index) => (
        <Box key={question.questionNumber} my={2}>
          <Question
            questionNumber={question.questionNumber}
            question={question.question}
            description={question.description}
            gifUrl={question.gifUrl}
            followUp={question.followUp}
            existingResponse={responses[index] || undefined} // Pass existing response
            onResponse={(response) => handleResponse(index, response)}
          />
        </Box>
      ))}
      {error && (
        <Box my={2}>
          <Alert severity="error">{error}</Alert>
        </Box>
      )}
      <Box textAlign="center" my={4}>
        <Button
          sx={{
            backgroundColor: "#02A479",
            borderRadius: "16px",
            width: "40%",
            alignSelf: "center",
          }}
          endIcon={<ArrowForwardIcon />}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          Next
        </Button>
      </Box>
    </Container>
  );
};

export default MultiQuestionnaire;
